import React, { Component } from 'react';
import PrivatePage from '../../common/containers/PrivatePage';
import Content from '../../common/containers/Content';
import Col from '../../common/containers/Col';
import Row from '../../common/containers/Row';
import Panel from '../../common/containers/Panel';
import Textarea from '../../common/components/form/Textarea';
import Button from '../../common/components/Button';
import Waiting from '../../common/containers/Waiting';
import { AlertError, AlertSuccess } from '../../common/components/Alert';
import Ajax from '../../common/ajax';
import Config from '../../config';
import Input from '../../common/components/form/Input';
import truncate from 'underscore.string/truncate';
import Checkbox from '../../common/components/form/Checkbox';
import ListSelect from './ListSelect';
import CountrySelect from '../../common/components/form/CountrySelect';

class SubscribeProfessorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_subscribed: false,
      ies_initials: '',
      ies_name: '',
      country_iso_alpha_2: '',
      template_url: '',
      voucher_amount: 5,
      rawEmailList: '',
      list_id: '',
      emailList: [],
      processing: false,
      hasErrorOnLineNumber: false,
      error: false,
      success: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateTable = this.updateTable.bind(this);
  }

  handleInputChange(event) {
    event.persist();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      (state) => ({
        ...state,
        [name]: name === 'rawEmailList' ? this.cleanUpListText(value) : value,
      }),
      this.updateTable
    );
  }

  updateTable() {
    this.setState((state) => ({
      ...state,
      emailList: this.convertRawTextToObjects(state.rawEmailList),
    }));
  }

  cleanUpListText(value) {
    const lines = value.trim().split('\n');
    const newLines = [];
    if (lines.length) {
      lines.forEach((line) => {
        newLines.push(
          line
            .split(',')
            .map((i) => i.trim().replaceAll('"', ''))
            .join(',')
        );
      });
    }
    return newLines.join('\n');
  }

  convertRawTextToObjects(rawEmailList) {
    const emailList = [];
    const lines = rawEmailList.trim().split('\n');
    if (lines.length) {
      let lineErrorNumber = null;

      lines.forEach((line, idx) => {
        if (!line.trim()) {
          return;
        }
        // Gets the full name and email
        const items = line
          .trim()
          .split(',')
          .filter((i) => !!i.trim());

        if (items.length < 2 || items.length > 3) {
          lineErrorNumber = idx + 1;
          return;
        }

        if (items.length === 2) {
          // Adds the first name to the end
          items.push(items[0].trim().split(' ')[0].trim());
        }

        if (!this.validateEmail(items[1])) {
          lineErrorNumber = idx + 1;
          return;
        }

        if (items.length === 3) {
          // Adds to the list
          emailList.push({
            fullname: this.cleanUpName(items[0]),
            email: items[1].trim().toLowerCase(),
            first_name: this.cleanUpName(items[2]),
            ies_initials: this.state.ies_initials.trim(),
            template_url: this.state.template_url.trim(),
            voucher_amount: this.state.voucher_amount,
            is_subscribed: this.state.is_subscribed,
          });
        }
      });

      this.setState((state) => ({
        ...state,
        hasErrorOnLineNumber: lineErrorNumber,
      }));
    }
    return emailList;
  }

  cleanUpName(name) {
    const parts = [];
    name
      .trim()
      .split(' ')
      .forEach((part, idx) => {
        part = part.trim();
        if (part) {
          if (idx === 0 || part.length > 2 || part.indexOf('.') > -1) {
            parts.push(part[0].toUpperCase() + part.slice(1).toLowerCase());
          } else {
            parts.push(part.toLowerCase());
          }
        }
      });
    return parts.join(' ');
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState((state) => ({
      ...state,
      processing: true,
      success: false,
      error: false,
      completePercentage: 0,
    }));

    const data = {
      emailList: this.state.emailList,
      list_id: this.state.list_id,
      ies_name: this.state.ies_name,
      country_iso_alpha_2: this.state.country_iso_alpha_2,
    };

    Ajax.post(`${Config.apiHost}mailing/subscribe_professor/`, data)
      .done((response) => {
        this.setState((state) => ({
          ...state,
          processing: false,
          success: true,
          rawEmailList: '',
          emailList: [],
          is_subscribed: false,
        }));
      })
      .fail((jqXHR) => {
        this.setState((state) => ({
          ...state,
          error: jqXHR.responseText,
          processing: false,
        }));
      });
  }

  render() {
    return (
      <PrivatePage
        title="Inscrição de e-mails de professores"
        isAdminOnly={true}
      >
        <Content>
          <Row>
            <Col md={10} mdOffset={1}>
              <Panel title="Formulário de inscrição em lote">
                {this.state.success && (
                  <AlertSuccess>
                    Lista de e-mail enviada com sucesso.
                  </AlertSuccess>
                )}

                {this.state.error && (
                  <AlertError>
                    Erro ao enviar lista de e-mail.
                    <br />
                    <i>{this.state.error}</i>
                  </AlertError>
                )}

                {this.renderForm()}
              </Panel>
            </Col>
          </Row>
        </Content>
      </PrivatePage>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit}>
        <Waiting isProcessing={this.state.processing}>
          <Row>
            <Col md={2}>
              <Input
                label="Vouchers"
                name="voucher_amount"
                value={this.state.voucher_amount}
                required={true}
                type="number"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md={2}>
              <Input
                label="Sigla IES"
                name="ies_initials"
                value={this.state.ies_initials}
                required={true}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md={8}>
              <Input
                label="Nome IES"
                name="ies_name"
                value={this.state.ies_name}
                required={true}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                label="URL template"
                name="template_url"
                value={this.state.template_url}
                required={true}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md={3}>
              <ListSelect
                onChange={(list_id) =>
                  this.setState((s) => ({ ...s, list_id }))
                }
                required={true}
              />
            </Col>
            <Col md={3}>
              <CountrySelect
                onChange={(country_iso_alpha_2) =>
                  this.setState((s) => ({
                    ...s,
                    country_iso_alpha_2,
                  }))
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Checkbox
                id="is_subscribed"
                name="is_subscribed"
                checked={this.state.is_subscribed}
                label="Não enviar para o Sendy"
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>

          <Textarea
            label="Lista"
            name="rawEmailList"
            value={this.state.rawEmailList}
            onChange={this.handleInputChange}
            rows="10"
            required={true}
            showSelectedLineNumber={true}
            help="Cada linha de conter Nome,Email ou Nome,Email,PrimeiroNome"
            placeholder="Nome, E-mail"
          />

          {this.state.hasErrorOnLineNumber && (
            <AlertError>
              A lista contém erros na linha: {this.state.hasErrorOnLineNumber}
            </AlertError>
          )}
        </Waiting>

        <Button
          size="lg"
          submit={true}
          disabled={
            !this.state.emailList.length ||
            this.state.hasErrorOnLineNumber ||
            this.state.processing
          }
        >
          Enviar ao servidor
        </Button>

        {this.renderPreview()}
      </form>
    );
  }

  renderPreview() {
    return (
      <>
        <h3>Email list preview</h3>

        <table className="table table-striped">
          <thead>
            <tr>
              <th>Full name</th>
              <th>E-mail</th>
              <th>First name</th>
              <th>IES</th>
              <th>URL</th>
              <th>Vouchers</th>
            </tr>
          </thead>
          <tbody>
            {this.state.emailList.map((item, idx) => (
              <tr key={idx}>
                <td>{item.fullname}</td>
                <td>{item.email}</td>
                <td>{item.first_name}</td>
                <td>{item.ies_initials}</td>
                <td>{truncate(item.template_url, 50)}</td>
                <td>{item.voucher_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default SubscribeProfessorPage;
