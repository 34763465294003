import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Waiting from '../../containers/Waiting';
import Select from './Select';
import Config from '../../../config';
import Ajax from '../../ajax';

class CountrySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      processing: false,
    };
  }

  componentDidMount() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));

    Ajax.get(`${Config.apiHost}countries/`).done((countries) => {
      this.setState((state) => ({
        ...state,
        countries,
        processing: false,
      }));
    });
  }

  render() {
    return (
      <Waiting isProcessing={this.state.processing}>
        <Select
          label={this.props.label}
          name="country"
          value={this.state.country}
          onChange={(e) => this.props.onChange(e.target.value)}
          required={this.props.required}
          options={this.state.countries.map((i) => ({
            label: `${i.name} (${i.alpha_2})`,
            value: i.alpha_2,
          }))}
        />
      </Waiting>
    );
  }
}

CountrySelect.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

CountrySelect.defaultProps = {
  label: 'País',
  required: true,
};

export default CountrySelect;
